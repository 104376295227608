/*-首页上传-*/
.upload-wrap .content{
	padding: 6px;
	height: 225px;
	border: 1px solid #ccc;
	border-radius: 6px;
	background: #fff;
}

.upload-wrap .resumes > .content .select-cont{
	width: 80%;
	padding-top: 20px;
	margin: 0 auto;
}
.upload-wrap .resumes > .content .select-cont h4{
	font-weight: 400;
	font-size: 20px;
}
.upload-wrap .resumes > .content select{
	width: 100%;
	height: 34px;
	border-color: #ccc;
}
.upload-wrap .content-bottom{
	height: 40px;
	line-height: 40px;
}
.upload-wrap  > .text textarea{
	outline: none;
	resize: none;
	border: none;
	width: 100%;
	height: 100%;
	
}
.upload-wrap .upload .icon-shangchuan{
	font-size: 50px;
}
/*上传*/
.upload-wrap .krajee-default .file-other-icon{
	font-size: 106px;
	
}
.upload-wrap .krajee-default .file-other-icon i{
	font-size: 70px;
	color: #406eb3;
	vertical-align: top;
}
.upload-wrap .krajee-default .file-footer-caption{
	white-space: normal;word-break: break-all;
}
.upload .file-preview .fileinput-remove{
	display: none;
}
.upload-wrap .modal-tips{
	display: none;
}
.upload-wrap .modal-tips .s-error-wrap{
	display: table;
	width: 100%;
	height: 100%;
		
}
.upload-wrap .modal-tips .s-error-wrap .cont{
	display: table-cell;
	vertical-align: middle;
}

/*上传 end*/
.upload-panel{
	/*max-width: 800px;*/
}
.upload-panel .panel-body,
.upload-panel .panel-footer{
	background: #fafafa;
}
.upload-panel .panel-heading{
	padding: 5px 10px;
	padding-left: 30px;
}
.upload-panel .panel-body{
	padding: 15px 30px 0px 30px;
}
.upload-panel .panel-footer{
	border-top: none;
	padding: 15px 0 15px 30px;
	
}
.upload-panel a.btn-line-def{
	font-size: 12px;
}


.main-text-upload .file-drop-zone-title {padding-top: 2px;}
.main-text-upload .dropFileHere{font-size: 18px;}
.main-text-upload .or{font-size: 12px;}
.main-text-upload .krajee-default .file-footer-caption{font-size: 14px;width: 100%;}
.main-text-upload .upload-wrap .krajee-default .file-other-icon{ font-size: 0;display: block;    padding-top: 30px;}
.main-text-upload .krajee-default .file-thumbnail-footer{position: static;height: auto !important;}

.main-text-upload .modal-tips-wrap {box-shadow: 0 5px 10px rgba(0,0,0,.17);}
.main-text-upload .modal-tips-wrap .panel-heading{padding: 5px 10px;}

.main-text-upload .panel-body .nav-pills a{
	padding: 6px 12px;
}
.main-text-upload .panel-heading i{vertical-align: middle;}

.main-text-upload .nav-pills > li.active > a,
.main-text-upload .nav-pills > li.active > a:hover,
.main-text-upload .nav-pills > li.active > a:focus {
    color: #fff;
    background-color: #337ab7;
}
.main-text-upload .nav-pills>li>a,
.main-text-upload .nav-pills>li>a:hover{
	background-color: transparent;
	border: 1px solid #337ab7;
	margin-right: 15px;
}
.main-text-upload .nav-pills>li.active>a{
	border: 1px solid #337ab7;
}

/* ---首页上传 end------*/

/*match-play-vip   -----------*/
.match-play-vip > .row > .left {
	min-height: 480px;
}
.match-play-vip .left .border> div{
	padding-left: 34px;
	margin-bottom: 25px;
}
.match-play-vip .left > .border{
	padding: 35px 30px;
}
.match-play-vip .left .border> div.select-radio > .border{
	padding: 20px 15px;
}
.match-play-vip .left .border> div .radio img{
	margin-right: 3px;
}
.match-play-vip .left .border .title,
.match-play-vip .left .border> h5{
	font-size: 20px;
	font-weight: 400;
}
.match-play-vip .left .border .title{
	margin-left: -34px;
}
.match-play-vip .left .border> h5.nofb{
	font-weight: 300;
}
.match-play-vip .left .border> h5.nofb > .name{
	font-weight: 400;
}
.match-play-vip .left .border .title .ball,
.match-play-vip .left .border> h5>span{
	font-weight: 300;
}
.match-play-vip .left .border label{
	font-weight: 400;
}
.match-play-vip .left .radio input{
	cursor: pointer;
}

.match-play-vip .right .dropbox li{
	line-height: 2;
	margin-bottom: 10px;
	margin-left: 15px;
}
.match-play-vip .right .dropbox li span{
	margin-left: -15px;
}
.match-play-vip .right .dropbox li span .icon-jiantou-copy-copy{
	font-size: 14px;
}
.match-play-vip .right .title{
	font-size: 18px;
	font-weight: 400;
}
.match-play-vip .right .dropbox .title{
	padding-top: 20px;
}
/*match-play-vip  end -----------*/

/*------conver-pay-linkdin--------*/
.conver-pay-linkdin .left .border> div {
    padding-left: 0;
    /*margin-bottom: 0px;*/
}
.conver-pay-linkdin .left div.title{
	margin-bottom: 30px;
}
.conver-pay-linkdin .left .border > .title {
     margin-left: 0; 
}
.conver-pay-linkdin .left .title h2{
	margin-bottom: 20px;
}
.conver-pay-linkdin .left .title p{
	font-size: 16px;
}
.conver-pay-linkdin .left .content-wrap-l{
	padding-left: 35px;
}
.conver-pay-linkdin .left .content-wrap-l h5{
	font-weight: 400;
	font-size: 20px;
}
.conver-pay-linkdin .left .content-wrap-l h5 .ball{
	margin-left: -35px;
}
.conver-pay-linkdin .left .content-wrap-l button{
	width: 240px;
	font-size: 18px;
}
.conver-pay-linkdin .left .content-wrap-l a{
	color: #333;cursor: auto;
}
.conver-pay-linkdin .left .content-wrap-r .media-wrapper{
	margin-bottom: 10px;
	
}
.conver-pay-linkdin .left .content-wrap-r .media-wrapper .media{
	margin-bottom: 0;
}
.conver-pay-linkdin .left .content-wrap-r .media-wrapper .media-heading{
	font-weight: 400;
}
.conver-pay-linkdin .left .content-wrap-r .media-editor-wrapper .media-body,
.conver-pay-linkdin .left .content-wrap-r .media-editor-wrapper .media-left{
	display: block;
	width: 100%;
	text-align: center;
	margin-top: 10px;
}
.conver-pay-linkdin .left .content-wrap-r .desc{
	margin-bottom: 30px;
	color: #999;
	
}
.conver-pay-linkdin .left .upload-success-wrap{
	/*margin-top: 30px;*/	
	padding-left: 35px !important;
	margin-bottom: 25px;
}
.conver-pay-linkdin .upload-success-wrap-linkedindata{
	max-height: 500px; overflow: auto;
}
.conver-pay-linkdin .upload-success-wrap-linkedindata .desc{
	
}
.conver-pay-linkdin .left .upload-success-wrap h4{
	font-weight: 400;
	margin-bottom: 20px;
}
.conver-pay-linkdin .left .upload-success-wrap .cont{
	margin-bottom: 10px;
}
.conver-pay-linkdin .left .upload-success-wrap  .cont+p{
    border-bottom: 1px solid #d2d2d2;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.conver-pay-linkdin .left .upload-success-wrap  .cont+p:last-child{
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}
.conver-pay-linkdin .left .upload-success-wrap .cont label,
.conver-pay-linkdin .left .upload-success-wrap .cont i{
	font-weight: 300;
	color: #999;
	margin-bottom: 0;
}
.conver-pay-linkdin .left .content-wrap-r-job{
	padding-top: 90px;
}
.conver-pay-linkdin .left .content-wrap-r-job ul{
	margin-bottom: 25px;max-height: 500px;overflow: auto;
}
.conver-pay-linkdin .left .content-wrap-r-job li{
	border-bottom: 1px solid #d2d2d2;
	padding: 10px 0;
	
}
.conver-pay-linkdin .left .content-wrap-r-job li .checkbox{
	color: #999;
	margin-top: 0;
}
.conver-pay-linkdin .left .content-wrap-r-job li:last-child{
	border-bottom: none;
}
.conver-pay-linkdin .left .content-wrap-r-job button{
	font-size: 18px;
}
/*---*/
.conver-pay-linkdin .right .dropbox li {
	margin-bottom: 0;
}
.conver-pay-linkdin .right .dropbox .panel-body{
	padding-top: 10px;
}
.conver-pay-linkdin .right .dropbox .panel-body .bottom-btn{
	margin-top: 10px;
}
.conver-pay-linkdin .left .border> div.alert {
    padding-left: 15px;
}
/*------conver-pay-linkdin end--------*/

/*------covert 转换------*/
.conver .file-select-wrap .file-preview{
	border: none;
	height: 100%;
}
.conver .file-select-wrap .theme-explorer{
	height: 100%;
}
.conver .file-select-wrap .file-drop-zone{
	margin: 0;
	border: 0;
	padding: 0;
}
.conver .file-select-wrap .drop-file{
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 0;
}
.conver .preview-wrap .file-actions-cell {
	width: 100%;
}
.conver .file-select-wrap .file-highlighted {
    border: 2px dashed #fff !important;
    background-color: transparent;
}
.conver .file-select-wrap .select-file-btn{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 40px;
	z-index: 10;
}
.conver .preview-wrap .file-footer-buttons{
	text-align: right;
	padding-right: 15px;
    /*width: 280px;*/
}
.conver .preview-wrap .file-footer-buttons .kv-file-upload,
.conver .preview-wrap .file-footer-buttons .kv-file-remove,
.conver .preview-wrap .file-footer-buttons .kv-file-status{
	width: 78px;
}
.conver .file-error-message{
	margin: 15px 0;
	padding: 15px;
}
.conver .file-error-message ul,
.conver .file-error-message li{
	list-style: none;
}

/*covert 转换 end*/

/*适配来自mobile。css*/
@media only screen and (max-width:992px ) {
	body{font-size:14px;}
	.upload-panel .panel-heading {font-size: 16px;}
	.upload-panel .panel-heading i{font-size: 18px;}
	.main-text-upload .panel-body .nav-pills a {font-size: 14px;}
	.upload-panel .file-drop-zone-title {padding: 52px 10px;}
	.upload-panel .panel-body {padding: 15px 10px 0px 10px;}
	.upload-panel .panel-heading {padding-left: 10px;}
	.upload-wrap .krajee-default .file-other-icon {font-size: 80px;}
	.upload-wrap .krajee-default .file-other-icon i {font-size: 50px;vertical-align: middle;}
	.upload-wrap .content { height: 180px;}
	.upload-wrap .file-drop-zone {height: 160px;}
	.match-play-vip .left > .border{border: none;padding: 30px 0;}
	
	.match-play-vip .left .border .title, .match-play-vip .left .border> h5 {
	    padding-left: 35px;
	}
	.match-play-vip .left .border .title .ball, .match-play-vip .left .border> h5>span {
	    margin-left: -35px;
	}
	
	.panda-show .content {width: 90%; }
	.resume-preview-wrap {width: 100%; }
	.panda-show .swiper-slide {height: auto; padding: 0 20px; }
	.resume-template-detail{padding-top:30px;}
	.page-header {margin-top: 20px;}
	.panda-show .arrow-left {left: -30px;}
	.panda-show .arrow-right {right: -30px;}
	.convert .sub-title{font-size: 16px;}
	.conver .upload-panel .panel-heading {font-size: 20px;}
	.conver .upload-panel .panel-body> p {font-size: 16px;}
	
	.upload-panel .choose-file{bottom: auto;top: 50%;transform: translate(-50%,-50%);width: 100%;}
	.preview-wrap .file-preview-frame {display: block;margin-top: 0; margin-bottom: 20px;padding: 10px;}
	.preview-wrap .file-actions-cell,
	.preview-wrap .file-actions {display: block;}
	.conver .preview-wrap .file-footer-buttons {text-align: left; }
	.preview-wrap .file-details-cell{width: 100%;display: inline-block;padding-left: 50px;padding-right: 0;    margin-bottom: 5px;}
	.preview-wrap .kv-file-content {display: inline-block;float: left;margin-bottom: -45px;}
	.preview-wrap .file-details-cell .explorer-caption {display: inline-block;vertical-align: middle;width: 98%;}
	.preview-wrap .explorer-frame .file-other-icon i {margin-left: 0;}
	.preview-wrap .file-details-cell::before{content: '';display: inline-block;height: 50px;width: 1px;vertical-align: middle;}
	.file-preview-success .file-desc button{font-size: 12px;margin-right: 10px;}
	.preview-wrap .file-actions-cell .file-desc { margin-bottom: 10px;}
	.file-preview-error .status-btn{float: left;margin-bottom: -30px;}
	.file-preview-error .file-actions-cell .file-desc {padding-left: 30px;padding-top: 3px;}
	
	.conver-pay-linkdin .left .title h2 {font-size: 24px;}
	/*.conver-pay-linkdin .right{display:none;}*/
	.conver-pay-linkdin  .left .border .title{ padding-left: 0;}
	.conver-pay-linkdin .left .border> div.row > div{margin-bottom: 25px;}
	.conver-pay-linkdin .upload-show-how{padding: 0 35px;}
	.conver-pay-linkdin .content-wrap-r{padding-left: 35px;}
	.conver-pay-linkdin .left .content-wrap-r-job {padding-top: 10px;padding-left: 35px;}
	
}